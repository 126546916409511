<script setup lang="ts">
import { useRuntimeConfig, computed, useI18n, useFooterConfig, useTracker, useImage } from '#imports';

const img = useImage();

const { cdnImageUrl } = useRuntimeConfig().public;
const { storeLinks } = useFooterConfig();
const { locale } = useI18n();
const { jitsuEvent } = useTracker();

const mockupImg = computed(() => ({
  src: `${cdnImageUrl}/mockups/${locale.value}.png`,
  error: `${cdnImageUrl}/mockups/en.png`,
}));
</script>

<template>
  <div
    class="banner-container w-full h-[305px] bg-[#D9080E] flex justify-center items-center"
    :style="{
      'background-image': 'url(' + img('/img/illustration_bg.png') + '), url(' + img('/img/ellipses.png') + ')',
    }"
    data-testid="download-banner-container"
  >
    <div class="relative w-[625px] h-full">
      <NuxtImg
        :src="mockupImg.src"
        alt="device-app"
        format="webp"
        width="625"
        height="485"
        loading="lazy"
        class="absolute bottom-0"
      />
    </div>

    <div class="max-w-[310px]">
      <h2 class="text-extra text-white mb-30 font-bold">{{ $t('global.downloadappheadline') }}</h2>
      <div class="h-[45px] flex gap-x-10">
        <NuxtLink
          v-for="(store, index) in storeLinks"
          :key="index"
          :to="store.link"
          target="_blank"
          rel="nofollow"
          class="min-w-[142px] !h-[42px] rounded"
          @click="
            jitsuEvent('user-click-button', {
              event_name: store.event,
              object_name: 'upper-footer',
              object_parameter: store.event,
            })
          "
        >
          <NuxtImg
            :src="`/img/${store.img.src}`"
            :alt="store.alt"
            height="42"
            width="142"
            format="webp"
            loading="lazy"
            sizes="lg:284px"
            class="aspect-[142/42] w-full cursor-pointer"
            :placeholder="
              $device.isCrawler
                ? undefined
                : img('/img/' + store.img.fallback, {
                    sizes: 'lg:284px',
                    modifiers: {
                      format: 'webp',
                      width: 155,
                      height: 45,
                    },
                  })
            "
          />
        </NuxtLink>
      </div>
    </div>
  </div>

  <div class="w-full">
    <SharedNewsletterForm />
  </div>
</template>

<style scoped>
.banner-container {
  @apply bg-no-repeat;

  background-size: cover, contain;
  background-position: center, left 0 top 0;
}
</style>
